import React, {useEffect, useRef, useState} from 'react';
import * as styles from "./searchPart.module.scss";
import {useStoreContext} from "../provider/storeProvider";
import GsButton from "../page-components/buttons/gsButton";
import {Link} from "gatsby";

import ChevronRightSVG from "../icons/fontawesome/light/chevron-right.svg";

const SearchPart = () => {
    const storeContext = useStoreContext();

    const [ result, setResult ] = useState();
    const [ searchTerm, setSearchTerm ] = useState();
    const searchTermRef = useRef();
    const [ searchedTerm, setSearchedTerm ] = useState();

    const searchAction = async () => {

        setResult(null);

        const searchTerm = "*" + searchTermRef.current + "*";

        setSearchedTerm(searchTermRef.current);

        const productsQuery = storeContext.store.client.graphQLClient.query((root) => {
            // root.addConnection('products', { args: { first: 50, query: `title: "${searchTerm}" OR product_type: "${searchTerm}" OR vendor: "${searchTerm}"` } }, (product) => {
            root.addConnection('products', { args: { first: 50, query: `${searchTerm}` } }, (product) => {
                product.add('title');
                product.add("id");
                product.add("description");
                product.add("productType");
                product.add("handle");
                product.add("vendor");
                product.addConnection("images", { args: { first: 1 } }, (featuredImage) => {
                    featuredImage.add("src")
                });

            });
        });

        storeContext.store.client.graphQLClient.send(productsQuery).then(({model, data}) => {
            setResult(model);
        });
    };

    useEffect(
        () => {
            const listener = window.addEventListener("keydown", (e) => {

                if (e.code === "Enter") {
                    searchAction();
                }

            });

            return () => {
                window.removeEventListener( "keydown", listener);
            }
        },
        []
    );

    return <>

        <input type={"text"} className={styles.searchInput}
               onChange={
                   (val) => {
                       setSearchTerm(val.target.value);
                       searchTermRef.current = val.target.value;
                   }
               }
               placeholder={"Suche ..."} />

        <GsButton variant={"primary"} className={"d-inline-block ms-lg-2 mt-2"}
                  onClick={searchAction}>Suche starten</GsButton>

        {searchedTerm ?
            <>
                <h3 className={"my-4 text-primary-dark"}>Suchergebnisse für "{searchedTerm}"</h3>

                { result === null && searchedTerm && <p>Lade ...</p> }


                {result?.products?.length === 0 && <p>Keine Ergebnisse zu dem Suchbegriff!</p>}

                {
                    result && result.products && result.products.map(p => {

                        let description = p.description;

                        if (description.length > 1000) {
                            description = description.substring(0, 1000) + " ...";
                        }

                        return <div className={"my-5 align-items-center row"} key={p.id}>

                            <div className="col-lg-4 text-lg-end mb-2 mb-lg-0" >
                                {p.images && p.images.length > 0 &&
                                    <img className={"img-fluid " + styles.searchResultImg} src={p.images[0].src}/>
                                }
                            </div>

                            <div className="col-lg-8">

                                <h4>{p.title}</h4>

                                <p className={"text-accent mb-3"}>
                                    { p.vendor } ({ p.productType })
                                </p>

                                <div dangerouslySetInnerHTML={ { __html: description }} />


                                <Link
                                    className={"d-flex align-items-center mt-2"}
                                    to={"/produkt/" + p.handle}>

                                    <GsButton variant={"primary"} className={"d-flex-inline align-items-center"}>
                                        <ChevronRightSVG className={"svgFill me-1"} />
                                        Zum Produkt
                                    </GsButton>

                                </Link>

                            </div>

                        </div>
                    }

                        )
                }


            </>
            :
            <></>
        }

    </>
};

export default SearchPart;
