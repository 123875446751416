import  React from 'react';
import Layout from "../page-components/layout/layout";
import {useStoreContext} from "../provider/storeProvider";
import SearchPart from "../pages-part/searchPart";

const SuchPage = () => {


    return <Layout>

        <div className="container bg-white h-100">
            <div className="row">
                <div className="col-12 py-4">
                    <h1 className={"text-primary-dark"}>Suche</h1>

                    <SearchPart />

                </div>
            </div>
        </div>

    </Layout>

};

export default SuchPage;
